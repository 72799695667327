import { graphql } from "gatsby"
import React, { FC } from "react"
import { Layout } from "src/components/Layout"
import HeaderTeamsPage from "src/components/HeaderTeamsPage"
import { Props } from "./types"
import { usePages } from "src/context/pages"
import Logo from "src/images/structured-data-logo.png"
import * as styles from "./styles.module.scss"
import GetStarted from "src/components/Banners/GetStarted"
import ReadyToTry from "src/components/Banners/ReadyToTry"
import Img from "gatsby-image"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
}) => {
  const { currentPath } = usePages()

  return (
    <Layout
      title={texts.metaTitle}
      description={texts.metaDescription}
      openGraph={{
        title: texts.metaTitle,
        description: texts.metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
    >
      <HeaderTeamsPage
        title={texts.header}
        description={texts.description}
        button={true}
      />
      <div className={styles.main}>
        {texts.overviewTasks.slice(0, 4).map(task => (
          <div className={`content-wrapper ${styles.feature}`} key={task.label}>
            <span className={styles.label}>{task.label}</span>
            <h2 className={styles.title}>{task.title}</h2>
            <p className={styles.pagraph}>{task.description}</p>
            <Img
              fluid={task.image.childImageSharp.fluid}
              style={{
                height: "100%",
                width: "100%",
                maxWidth: task.maxW ? task.maxW : "none",
              }}
              className={styles.imgContainer}
            />
          </div>
        ))}
        <div className={styles.bannerBlue}>
          <ReadyToTry />
        </div>

        {texts.overviewTasks.slice(4, 8).map(task => (
          <div className={`content-wrapper ${styles.feature}`} key={task.label}>
            <span className={styles.label}>{task.label}</span>
            <h2 className={styles.title}>{task.title}</h2>
            <p className={styles.pagraph}>{task.description}</p>
            <Img
              fluid={task.image.childImageSharp.fluid}
              style={{
                height: "100%",
                width: "100%",
                maxWidth: task.maxW ? task.maxW : "none",
              }}
              className={styles.imgContainer}
            />
          </div>
        ))}
      </div>
      <div className={`content-wrapper ${styles.banner}`}>
        <GetStarted />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        header
        description
        metaTitle
        metaDescription
        overviewTasks {
          title
          label
          description
          image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1084) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          maxW
        }
      }
    }
  }
`

export default Template
