import React, { FC } from "react"
import * as styles from "./styles.module.scss"
import { Props } from "./types"
import { usePages } from "src/context/pages"
import { graphql, useStaticQuery } from "gatsby"
import { useComponentTexts } from "src/hooks/useComponentTexts"
import Link from "src/utils/Link"

const ReadyToTry: FC<Props> = ({}) => {
  const { getPage } = usePages()
  const data = useStaticQuery(graphql`
    query {
      allFile (filter: { relativePath: {regex: "/components/Banners/ReadyToTry\/.+\\.yml/"}}) {
        nodes {
          childYaml {
            title
            description
            buttonText
          }
          relativePath
        }
      }
    }
  `)
  const texts = useComponentTexts(data)
  return (
    <div className={styles.banner}>
      <h3 className={styles.title}>{texts.title}</h3>
      <p className={styles.description}>{texts.description}</p>
      <Link
        to={getPage("signup").url}
        className={`button-new button-new--scale ${styles.button}`}
      >
        {texts.buttonText}
      </Link>
    </div>
  )
}

export default ReadyToTry
